/** @format */

export const CLEANER = "cleaner";
export const MANAGER = "manager";
export const SUPER_ADMIN = "super_admin";
export const HQ = "hq";
export const RE = "recruiter";
export const MO = "medical_officer";
export const DOCTOR = "doctor";
export const GenericUsers = [2];
