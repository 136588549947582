/** @format */
import {
  DELETECallHelper,
  GETCallHelper,
  POSTCallHelper,
} from "../../services";
import { handleError } from "../../errorServices";
export default {
  async fetchAllMedicalLetters() {
    try {
      let url = `/mo/medical-letters/fetch-medical-letters`;
      const response = await GETCallHelper(url);
      return response?.data?.data;
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchMedicalLettersRequests({ commit }, payload) {
    try {
      let url = `/mo/medical-letters?status=${payload.status}&page=${
        payload.page
      }${
        payload?.medicalLetter ? `&medical_letter=${payload.medicalLetter}` : ""
      }${payload?.date ? `&date=${payload.date}` : ""}${
        payload?.search ? `&search=${payload.search}` : ""
      }`;
      const response = await GETCallHelper(url);
      commit("setMedicalLettersRequests", response?.data?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchMedicalLetterRequest({ commit }, payload) {
    try {
      let url = `/mo/medical-letters/medical-letter-request?medical_letter_request=${payload.letterId}`;
      const response = await GETCallHelper(url);
      commit("setMedicalLetterRequest", response?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },
  async fetchMedicalLetterRequestQuestions({ commit }, payload) {
    try {
      let url = `/mo/medical-letters/fetch-question-answers?medical_letter_request=${payload.letterRequestId}&medical_letter=${payload.letterId}`;
      const response = await GETCallHelper(url);
      commit("setMedicalLetterRequestQuestions", response?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async createSignature({ commit }, payload) {
    try {
      let url = "/mo/medical-letters/create-signature";
      const response = await POSTCallHelper(url, payload);
      commit("updateSignature", response?.data?.data);
    } catch (e) {
      handleError(e);
    }
  },
  async fetchSignature({ commit }, payload) {
    try {
      let url = "/mo/medical-letters/fetch-medical-letter-signatures";
      const response = await GETCallHelper(url, payload);
      commit("setSignature", response?.data?.data);
    } catch (e) {
      handleError(e);
    }
  },
  async deleteSignature(_, payload) {
    try {
      let url =
        "/mo/medical-letters/delete-medical-letter-signature?medical_letter_signature=";
      await DELETECallHelper(url, payload);
    } catch (e) {
      handleError(e);
    }
  },
  async editSignature({ commit }, payload) {
    try {
      let url = `/mo/medical-letters/update-signature?medical_letter_signature=${payload?.signature?.signatureId}`;
      const response = await POSTCallHelper(url, payload.formdata);
      commit("editSignature", {
        response: response?.data?.data,
        signatureId: payload?.signature?.index,
      });
    } catch (e) {
      handleError(e);
    }
  },

  async approvedMedicalLetterRequest(_, payload) {
    try {
      let url = `/mo/medical-letters/mo-notes`;
      await POSTCallHelper(url, payload);
    } catch (e) {
      handleError(e);
    }
  },

  async createDoctorProfile({ commit }, payload) {
    try {
      let url = "/hq/medical-letters/create-doctor-profile";
      await POSTCallHelper(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 422) {
        commit(
          "setEmailError",
          "Email is already in use, please use another email."
        );
      }
      handleError(e);
    }
  },
  async fetchDoctorsProfile({ commit }, payload) {
    try {
      let url = `/hq/medical-letters/fetch-doctor-profiles?${
        payload?.page ? `page=${payload.page}` : ""
      }${payload?.search ? `&search=${payload.search}` : ""}`;
      const response = await GETCallHelper(url);
      commit("setDoctorsProfile", response.data);
    } catch (e) {
      handleError(e);
    }
  },
  async fetchDoctorProfile({ commit }, payload) {
    try {
      let url = `/hq/medical-letters/doctor-profile?doctor=${payload.doctorId}`;
      const response = await GETCallHelper(url);
      commit("setDoctorProfile", response.data.data);
    } catch (e) {
      handleError(e);
    }
  },
  async updateDoctorProfile(_, payload) {
    try {
      let url = "/hq/medical-letters/update-doctor-profile";
      await POSTCallHelper(url, payload);
    } catch (e) {
      handleError(e);
    }
  },
  async fetchDoctorsProfileMO() {
    try {
      let url = `/mo/medical-letters/fetch-doctor-profiles`;
      const response = await GETCallHelper(url);
      return response?.data?.data;
    } catch (e) {
      handleError(e);
    }
  },

  /***
   * FOR DOCTOR
   */

  async fetchMedicalLettersRequestsByDoctor({ commit }, payload) {
    try {
      let url = `/doctor/medical-letters?status=${payload.status}&page=${
        payload.page
      }${
        payload?.medicalLetter ? `&medical_letter=${payload.medicalLetter}` : ""
      }${payload?.date ? `&date=${payload.date}` : ""}${
        payload?.search ? `&search=${payload.search}` : ""
      }`;
      const response = await GETCallHelper(url);
      commit("setMedicalLettersRequests", response?.data?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchMedicalLetterRequestByDoctor({ commit }, payload) {
    try {
      let url = `/doctor/medical-letters/medical-letter-request?medical_letter_request=${payload.letterId}`;
      const response = await GETCallHelper(url);
      commit("setMedicalLetterRequest", response?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async fetchMedicalLetterRequestQuestionsByDoctor({ commit }, payload) {
    try {
      let url = `/doctor/medical-letters/fetch-question-answers?medical_letter_request=${payload.letterRequestId}&medical_letter=${payload.letterId}`;
      const response = await GETCallHelper(url);
      commit("setMedicalLetterRequestQuestions", response?.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },

  async sendMedicalLetter(_, payload) {
    try {
      let url = `/doctor/medical-letters/send-medical-letter`;
      const response = await POSTCallHelper(url, payload);
      return response?.data?.data;
    } catch (e) {
      handleError(e);
    }
  },
  async rejectMedicalLetterRequest(_, payload) {
    try {
      let url = `/doctor/medical-letters/reject`;
      await POSTCallHelper(url, payload);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchDoctor({ commit }) {
    try {
      let url = `/doctor/medical-letters/doctor-profile`;
      const response = await GETCallHelper(url);
      commit("setDoctor", response.data.data);
    } catch (e) {
      handleError(e);
    }
  },
};
