/** @format */

export default {
  getMedicalLettersRequests(state) {
    return state.medicalLetterRequests;
  },
  getMedicalLetterRequest(state) {
    return state.medicalLetterRequest;
  },
  getMedicalLetterRequestQuestions(state) {
    return state.medicalLetterRequestQuestions;
  },
  getSignature(state) {
    return state.signature;
  },
  getDoctorsProfile(state) {
    return state.doctorsProfile;
  },
  getDoctorProfile(state) {
    return state.doctorProfile;
  },
  getDoctor(state) {
    return state.doctor;
  },
  getEmailError(state) {
    return state.emailError;
  },
};
